<template>
    <div class="portal_pages">
        <div class="result_wpr new">
            <div class="actions mt-2 mb-2">
                <h3 class="section_title">
                    My {{title}}
                    <div class="categories_list" @click="filterList = !filterList" v-click-outside="closeFilters">
                        {{ filter }} <i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                            <ul>
                                <li v-for="(filter, f) of filters" :key="f" @click="filterFiles(filter)">{{ filter.name }}</li>
                            </ul>
                        </div>
                    </div>
                    <button type="button" class="add-file-button" @click="openAddFileDrawer"><i class="fa fa-plus-circle"></i>Add File</button>
                </h3>
                <ul>
                    <li class="optionDrops toggle_switch">
                        <label for="toggle-arhived" class="switch_option capsule_btn p-0">
                            <h5>Show Archived</h5>
                            <input type="checkbox" id="toggle-arhived" :true-value="1" :false-value="0" v-model="params.archived" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                    <li class="optionDrops search_area" :class="{'active': searchField}">
                        <input type="text" placeholder="Search" :disabled="loading" ref="search" v-model.trim="params.search" />
                        <button class="search_btn" @click="searchField = !searchField;">
                            <i class="fas fa-search"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="list_container">
            <ul class="playbook_list">
                <li v-for="(file, f) in files.data" :key="f">
                    <div class="plybook_item">
                        <div class="item_img">
                            <iframe v-if="file.is_doc_file" :src="`https://docs.google.com/gview?url=${file.path}&embedded=true`" height="200px" width="100%" class="img_frame" scrolling="no" frameborder="0"></iframe>
                            <img v-else-if="!file.has_icon" :src="file.thumbnail" class="img" :class="{ 'file-icon': file.has_icon }" @error="setDefaultImage" alt="...">
                            <img v-else src="@/assets/images/default-file.svg" class="img" :class="{ 'file-icon': file.has_icon }" @error="setDefaultImage" alt="...">
                            <ul class="statistics">
                                <li></li>
                                <li>{{ file.views }} Views</li>
                                <li>{{ file.downloads }} Downloads</li>
                                <li>{{ moment.utc(file.created_at).local().format('ll | LT') }}</li>
                            </ul>
                        </div>
                        <a class="item_ttl pointer" @click="viewFile(file)" :title="file.name">
                            {{ Helper.ellipsis(file.name, 20) }}
                        </a>
                        <div class="item_info">
                            <span class="tag">{{ file.ext }}</span>
                            <ul>
                                <li @click="viewFile(file)"><i class="fas fa-eye"></i></li>
                                <li @click="moreOption(file.id)">
                                    <i class="fas fa-ellipsis-v"></i>
                                    <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  file.id}">
                                        <li><a class="dropdown-item" href="javascript:void(0);">Copy short link</a></li>
                                        <li><a class="dropdown-item" href="javascript:void(0);">Download</a></li>
                                        <li v-if="params.archived"><a class="dropdown-item" href="javascript:void(0);">Unarchive</a></li>
                                        <li v-if="!params.archived"><a class="dropdown-item" href="javascript:void(0);">Archive</a></li>
                                        <li><a class="dropdown-item" href="javascript:void(0);">Delete</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="empty_section" v-if="files.total == 0">
                <img src="@/assets/images/empty_habit_tracking.svg" alt="Empty">
                <h1 class="text-center">
                    {{ params.search ? 'No records found.' : (params.archived ? 'No records found.' : `Oops, it looks like this place is as empty as a library in a ghost town. No uploads here… yet! Time to add some progress pics!`) }}
                </h1>
            </div>
            <div class="pagination" v-show="files.total">
                <pagination v-model="params.page" :pages="files.last_page" :range-size="0" @update:modelValue="handlePagination" />
            </div>
        </div>
        <file-preview v-model="previewDrawer" :file="selectedFile" :refresh-files="refreshFiles" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment'
    import Helper from '@/utils/Helper'

    const FilePreview = defineAsyncComponent(() => import('@/pages/file/components/Preview'))

    export default {
        name: 'Client Portal Workouts',

        data () {
            return {
                searchField: false,
                params: {
                    search: '',
                    per_page: 12,
                    page: 1,
                    last_page: 1,
                    total: 0,
                    filter: 'due',
                    from: 0,
                    to: 0,
                    archived: false,
                    field: 'id',
                    order: 'desc',
                },
                filter: 'Date Added',
                addDrawer: false,
                previewDrawer: false,
                filters: [
                    {
                        field: 'created_at',
                        name: 'Date Added',
                        order: 'desc'
                    },
                    {
                        field: 'updated_at',
                        name: 'Date Modified',
                        order: 'desc'
                    },
                    {
                        field: 'id',
                        name: 'Newest',
                        order: 'desc'
                    },
                    {
                        field: 'id',
                        name: 'Oldest',
                        order: 'asc'
                    },
                    {
                        field: 'name',
                        name: 'A-Z',
                        order: 'asc'
                    },
                    {
                        field: 'name',
                        name: 'Z-A',
                        order: 'desc'
                    },
                ],
                moment: moment,
                Helper: Helper,
                selectedFile: {},
                filterList: false,
                moreOptionDropdown: 0,
                isMoreOption: false,
                isMobileScreen: false,
            };
        },

        props: {
            title: String,
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                if (!vm.awaitingSearch) {
                    setTimeout(() => {
                      vm.fetchFiles(vm.params);
                      vm.awaitingSearch = false;
                    }, 1000);
                }

                vm.awaitingSearch = true;
            },

            'params.archived' (val) {
                const vm = this;

                vm.fetchFiles(vm.params);
            },

            addDrawer (val) {
                const vm = this;

                if (val == false) {
                    vm.fetchFiles(vm.params);
                    document.body.classList.remove('modal-open');
                } else {
                    document.body.classList.add('modal-open');
                }
            },

            previewDrawer (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        components: {
            FilePreview,
        },

        computed: mapState({
            files: state => state.clientPortalModule.files,
            loading: state => state.clientPortalModule.assetsLoader,
        }),

        mounted () {
            const vm = this;

            vm.fetchFiles(vm.params);

            if (vm.previewFile && vm.previewFile.id) {
                vm.viewFile(vm.previewFile);
            }

            vm.isMobileScreen = false;

            if (window.innerWidth < 767) {
                vm.isMobileScreen = true;
            }

            window.addEventListener('resize', () => {
                if (window.innerWidth < 767) {
                    vm.isMobileScreen = true;
                } else {
                    vm.isMobileScreen = false;
                }
            });
        },

        unmount () {
            const vm = this;

            window.removeEventListener('resize', () => {
                vm.isMobileScreen = false;
            });
        },

        methods: {
            ...mapActions({
                fetchFiles: 'clientPortalModule/fetchFiles',
            }),

            filterFiles (filter) {
                const vm = this;

                vm.filter       = filter.name;
                vm.params.page  = 1;
                vm.params.field = filter.field;
                vm.params.order = filter.order;

                vm.fetchFiles(vm.params);
            },

            viewFile (file) {
                const vm = this;

                if (file.can_view) {
                    vm.previewDrawer  = true;
                    vm.selectedFile   = file;

                    file.views ++;
                } else {
                    vm.handlePermissionError('view');
                }
            },

            openAddFileDrawer () {
                const vm = this;

                vm.addDrawer = true;
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.fetchFiles(vm.params);
            },

            closeFilters () {
                const vm = this;

                vm.filterList = false;
            },

            moreOption (id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption = false;
                }, 100);
            },

            refreshFiles (silent = false) {
                const vm = this;

                vm.fetchFiles(vm.params);
            },
        },
    }
</script>

<style scoped>
    h3.section_title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #121525;
        margin: 0;
        align-items: center;
    }
    .add-file-button{
        font-size: 13px;
        line-height: 16px;
        margin-left: 20px;
        cursor: pointer;
    }
    .add-file-button i{
        color: #2f7eed;
        margin-right: 5px;
    }
    .toggle_switch{
        margin-right: 30px;
    }
    .inner_pages .actions.filter_bar {
        border-radius: 5px;
        border: 1px solid #eaeaea;
    }

    .inner_pages .actions.filter_bar li.option_drop {
        max-width: 250px;
    }

    .inner_pages .actions.filter_bar .dropdown_wpr {
        width: 100%;
    }

    .playbook-desc h4 {
        height: 40px;
    }

    .playbook-desc p {
        height: 50px;
    }

    .filter_bar li {
        position: relative;
    }

    .portal_playbooks {
        margin-bottom: 40px;
    }

    .portal_playbooks .client_productList {
        display: flex;
        flex-wrap: wrap;
        margin: 30px -13px 20px -13px;
    }

    .portal_playbooks .client_productList li {
        flex: 0 0 25%;
        padding: 15px;
    }

    .portal_playbooks .client_productList .product_card {
        width: 100%;
    }

    .inner_pages .actions.filter_bar {
        border-radius: 5px;
        border: 1px solid #eaeaea;
    }

    .inner_pages .actions.filter_bar li.option_drop {
        max-width: 250px;
    }

    .inner_pages .actions.filter_bar .dropdown_wpr {
        width: 100%;
    }

    .playbook-desc h4 {
        height: 40px;
    }

    .playbook-desc p {
        height: 50px;
    }

    .filter_bar li {
        position: relative;
    }

    .empty_section {
        /* border: 1px solid #D9D9D9; */
        border-radius: 10px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        margin: 10px 0 30px 0;
    }
    .empty_section img{
        max-height: 90px;
        width: auto;
        margin-bottom: 30px;
    }
    .empty_section h1 {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        color: #2c3e50;
        /* font-style: italic; */
    }

    .dashboard_content .right_side .extra_info {
        border: 0;
    }

    .dashboard_content .right_side .extra_info .action_wpr {
        position: relative;
        margin-bottom: -10px;
    }

    :deep(.pagination li button.Page-active) {
        background-color: var(--var-portal-tab-background-color) !important;
        border-color: var(--var-portal-tab-background-color) !important;
        color: var(--var-portal-tab-text-color) !important;
    }

    :deep(.custom_element) {
        background-color: var(--var-portal-tab-background-color) !important;
        border-color: var(--var-portal-tab-background-color) !important;
        color: var(--var-portal-tab-text-color) !important;
    }

    .portal_playbooks {
        width: 100%;
    }

    .filter_bar button {
        padding: 7px 15px;
        border: 1px solid var(--var-element-bg-color, #D4D4D4);
        border-radius: 5px;
        background: var(--var-element-bg-color, #D4D4D4);
        color: var(--var-element-text-color, #2C3E50);
    }

    .playbook_list li .tag {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 15px;
        color: var(--var-portal-tab-background-color);
        border-radius: 3px;
        background: var(--var-portal-tab-bg-color12);
        text-transform: uppercase;
    }

    .playbook_list {
        list-style-type: none;
        margin: 0 -10px;
        display: flex;
        flex-wrap: wrap;
    }

    .playbook_list>li {
        padding: 15px 10px;
        width: 25%;
    }

    .plybook_item {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.02);
        border-radius: 6px;
        line-height: 0;
        position: relative;
        height: 100%;
        background: #fff;
    }

    .plybook_item .item_img .checkbox {
        position: absolute;
        left: 15px;
        top: 15px;
    }

    .plybook_item .item_img .checkbox span i {
        color: #fff;
    }

    .plybook_item .item_img {
        height: 200px;
        position: relative;
        overflow: hidden;
        border-radius: 6px 6px 0 0;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .plybook_item .item_img .img {
        height: 200px;
        width: 100%;
        object-fit: cover;
    }
    .plybook_item .item_img .img_frame{
        height: calc(200px + 24px);
        width: calc(100% + 24px);
        margin: -12px;
        object-fit: cover;
    }
    .plybook_item .item_img .img.file-icon{
        height: 80px;
        width: auto;
        object-fit: contain;
    }

    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: linear-gradient(to left, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.7));
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .plybook_item .item_img .statistics li {
        font-size: 12px;
        line-height: 15px;
        color: #ebebeb;
        padding: 3px 0;
        text-align: left;
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }

    .plybook_item .img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        border-radius: 6px 6px 0 0;
    }

    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 30px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .plybook_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .plybook_item .item_info>ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info>ul>li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #e9e9e9;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .plybook_item .item_info .row_option {
        bottom: -10px;
        top: auto;
        animation: dropMove2 .5s ease-in-out;
    }

    .plybook_item .item_info .row_option:before {
        bottom: 18px;
        top: auto;
        left: auto;
        transform: rotate(45deg) scale(1);
    }

    .plybook_item .item_img:hover .statistics,
    .plybook_item .item_img.active .statistics {
        opacity: 1;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }
    .result_wpr.new .actions > ul{
        gap: 0;
        height: auto;
    }
    .result_wpr.new .actions > ul li.search_area{
        background: #fff;
        height: 35px;
        margin-left: auto;
    }
    .result_wpr.new .actions > ul li.search_area input[type=text]{
        font-size: 11px !important;
    }
    .result_wpr.new .actions > ul li.search_area.active input{
        width: 250px;
        padding: 0 15px;
    }
    .result_wpr.new .actions > ul li button{
        font-size: 13px;
        line-height: 18px;
        cursor: pointer;
    }
    .categories_list{
        padding: 5px 15px;
        border-radius: 15px;
        background: #e9e9e9;
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        position: relative;
        min-width: 100px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .categories_list i{
        margin-left: auto;
        padding-left: 10px;

    }
    .categories_list .dropdown_wpr{
        min-width: 170px;
    }
    .categories_list .dropdown_wpr::before{
        display: none;
    }
    @media(max-width: 991px){
        h3.section_title{
            width: 100%;
            margin-bottom: 20px;
            justify-content: flex-start;
        }
        .categories_list{
            order: 1;
            margin-left: auto;
        }
        .result_wpr.new .actions > ul{
            width: 100%;
        }
    }
    @media(max-width: 767px){
        .playbook_list>li {
            padding: 15px;
            flex: 0 0 50% !important;
            width: 50% !important;
        }
        .result_wpr.new .actions > ul li.search_area{
            border-radius: 17px !important;
        }
    }
    @media(max-width: 699px){
        .result_wpr.new .actions > ul li.search_area input[type=text]{
            width: 250px;
        }
    }
    @media(max-width: 499px){
        .result_wpr.new .actions > ul{
            padding: 0;
            row-gap: 15px;
        }
        .categories_list{
            margin-bottom: -80px;
        }
        .result_wpr.new .actions > ul li.search_area{
            width: 100%;
            order: 1;
        }
        .result_wpr.new .actions > ul li.search_area input,
        .result_wpr.new .actions > ul li.search_area.active input{
            width: 100%;
            padding: 0 0 0 15px;
        }
        .empty_section h1{
            font-size: 13px;
            line-height: 23px;
        }
    }
    @media(max-width: 450px){
        .list_container{
            max-width: 300px;
            margin: 0 auto;
        }
        .playbook_list>li {
            flex: 0 0 100% !important;
            width: 100% !important;
        }
    }
</style>